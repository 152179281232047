import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { PagetitleComponent } from './components/pagetitle/pagetitle.component';
import { ToastrModule } from 'ngx-toastr';
import { PhonePipe } from '../core/pipes/phone.pipe';
import { DateWithoutTimezonePipe } from '../core/pipes/date-without-timezone.pipe';
import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component';

@NgModule({
  declarations: [
    LoaderComponent,
    PagetitleComponent,
    PhonePipe,
    DateWithoutTimezonePipe,
    SessionTimeoutComponent
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot({ timeOut: 3000 }),
  ],
  exports: [
    LoaderComponent,
    PagetitleComponent,
    PhonePipe,
    DateWithoutTimezonePipe,
    SessionTimeoutComponent
  ]
})

export class SharedModule { }
