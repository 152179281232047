export interface IEnvironment {
    production: boolean;
    apiBaseUrl: string;
    loginUrl: string;
    remitAppUrl: string;
    adminAppUrl: string;
    adminApiBaseUrl: string;
    phiAppUrl: string;
    studiesAppUrl: string;
    billerAppUrl: string;
    chartsAppUrl: string;
    signatureKey: string;
}

export enum AvailableApplication {
    Admin = 'admin',
    Remit = 'remit',
    Phi = 'phi',
    Biller = 'biller',
    Studies = 'studies',
    Charts = 'charts',
    Vascunote = 'vascunote'
}