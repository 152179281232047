import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';
import { Observable, catchError, map, of } from 'rxjs';
import { InterpretationRequest, PatientSearchResult, PatientStudy } from '../models/patient-study.model';
import { DateWithoutTimezonePipe } from '../pipes/date-without-timezone.pipe';
import { EnumerationItem } from './utils';

@Injectable({
  providedIn: 'root'
})
export class PatientStudyService {

  constructor(private readonly _dataService: DataService,
    private readonly _dateWithoutTimezone: DateWithoutTimezonePipe) { }

  getAllStudies(): Observable<PatientStudy[]> {
    let url = this.apiBaseUrl + 'api/v1/studies';
    return this._dataService.get(url).pipe(
      map(studies => {
        return studies.map(std => ({
          ...std,
          formattedStudyDate: this._dateWithoutTimezone.transform(std.studyDate, 'MM/dd/yyyy')
        }))
      }));
  }

  refreshStudies(): Observable<PatientStudy[]> {
    let url = this.apiBaseUrl + 'api/v1/studies/sync-studies';
    return this._dataService.post(url, null).pipe(
      map(studies => {
        return studies.map(std => ({
          ...std,
          formattedStudyDate: this._dateWithoutTimezone.transform(std.studyDate, 'MM/dd/yyyy')
        }))
      }));
  }

  saveInterpretation(request: InterpretationRequest) {
    const url = this.apiBaseUrl + `api/v1/studies/interpretation`;
    return this._dataService.post(url, request)
  }

  getFileByStudyUrl(ids: string[]): Observable<string> {
    let url = this.apiBaseUrl + `api/v1/studies/patient/study-view`;
    return this._dataService.post(url, ids).pipe(
      map(response => response.url)
    )
  }

  searchPatientByPartialName(searchTerm: string): Observable<PatientSearchResult[]> {
    if (!searchTerm) {
      return of(null);
    }
    const url = `${this.apiBaseUrl}api/v1/patients/search/term?term=${encodeURIComponent(searchTerm)}`;
    return this._dataService.get(url);
  }

  get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }
}
